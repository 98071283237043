html {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

body {
  font-size: 0.875rem;
}

.xplore-section {
  @apply max-w-screen-xl mb-24 lg:mb-28 w-full ml-auto mr-auto pl-6 pr-6
}

.xplore-btn {
  text-align: center;
  @apply text-white font-bold p-3 rounded-md shadow px-5 bg-xp-lightgreen-500 hover:bg-xp-lightgreen-600 hover:-translate-y-0.5 transform transition-all duration-150
}

.xplore-btn.full {
  @apply w-full
}


.xplore-title {
  @apply text-2xl md:text-3xl mb-0 mt-0 font-bold
}

.xplore-subtitle {
  color: #505050;
  max-width: 800px;
  font-weight: 600;
  @apply mb-8 text-sm md:text-lg mt-0 leading-tight
}


#newsContent * {
  font-family: Poppins, sans-serif !important;
}

#newsContent img {
  width: 100% !important;
  object-fit: contain !important;
  min-height: 250px !important;
}

.MuiAccordionSummary-root {
  background-color: #00BFA6 !important;
  color: white;

}

.MuiAccordion-root {
  margin-bottom: 12px;
}

.MuiAccordionSummary-content p {
  color: white !important;
  font-weight: bold;
}

.MuiAccordionSummary-expandIcon {
  color: white !important;
}
.MuiPaginationItem-page.Mui-selected {
  background-color: #0A8F7E !important;
  color: white !important;
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  /* display: none; <- Crashes Chrome on hover */
  -webkit-appearance: none;
  margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
}

input[type=number] {
  -moz-appearance:textfield; /* Firefox */
}

body, #__next {
  background-color: #fafafa !important;
}
a {
  color: #0A8F7E;
  font-weight: bold;
}

* {
  font-family: Poppins, sans-serif;
}

.MuiInputBase-root::before {
  border-bottom: 2px solid #262626 !important;
}

.MuiInputBase-root::after {
  border-bottom: 3px solid #262626 !important;
}

.StripeElement {
  box-sizing: border-box;

  height: 40px;

  padding: 10px 12px;

  border: 1px solid  rgba(27,27,27,0.3);
  border-radius: 8px;
  background-color: white;

  box-shadow: 0 1px 3px 0 #e6ebf1;
  -webkit-transition: box-shadow 150ms ease;
  transition: box-shadow 150ms ease;
}

.StripeElement--focus {
  box-shadow: 0 1px 3px 0 #cfd7df;
}

.StripeElement--invalid {
  border-color: #fa755a;
}

.StripeElement--webkit-autofill {
  background-color: #fefde5 !important;
}

.react-tel-input .form-control:focus {
  box-shadow: 0 0 0 1px #00BFA6 !important;
  border-color: #00BFA6 !important;
}

.special-label {
  color: rgba(27,27,27,0.6) !important;
  display: block;
  background: white;
  font-family: 'Poppins', sans-serif;
  font-weight: 600;
  font-size: 14px !important;
  transform: scale(0.75);
  left: -15px !important;

}
.mapImage {
  object-fit: cover;
  border-radius: 40px;
  background-color: #ecebeb;
}

@media (max-width: 768px) {
    .CookieConsent div:nth-child(2) {
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
  }
  }

h1, h2, h3, h4, h5 ,h6 {
  display: block;
  font-weight: bold;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

h1 {
  font-size: 2em;
  margin-block-start: 0.67em;
  margin-block-end: 0.67em;
}

h2 {
  font-size: 1.5em;
  margin-block-start: 0.83em;
  margin-block-end: 0.83em;
}

h3 {
  font-size: 1.17em;
  margin-block-start: 1em;
  margin-block-end: 1em;
}

h4 {
  font-size: 1em;
  margin-block-start: 1.33em;
  margin-block-end: 1.33em;
}

h5 {
  font-size: 0.83em;
  margin-block-start: 1.67em;
  margin-block-end: 1.67em;
}

h6 {
  font-size: 0.67em;
  margin-block-start: 2.33em;
  margin-block-end: 2.33em;
}

p {
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0;
  margin-inline-end: 0;
}

li {
  display: list-item;
  margin-bottom: 1rem;
}

ol, ul {
  margin-block-start: 1em;
  margin-block-end: 1em;
  padding-inline-start: 40px;
}

ol {
  list-style-type: decimal;
}

ul {
  list-style-type: disc;
}
